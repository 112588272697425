import { Box, Flex } from "@chakra-ui/react";
import { YellowColor } from "../theme";

export function PerksText(props: {
    children: string
}) {
    return <Flex
        bgColor={YellowColor}
        color="#0B0B0E"
        flexShrink={0}
        gap="20px"
        style={{ writingMode: "vertical-lr" }}
        transform={"rotate(180deg)"}
    >
        <Box fontWeight={600}>{props.children}</Box>
    </Flex>
}