import { Button } from "@chakra-ui/react";

export function GamblyButton(props: {
    children?: any
    onClick?: () => void,
    color?: string
    p?: string
    radius?: string
    squareSize?: string
}) {
    

    return <Button onClick={props.onClick}
        padding={props.p ?? "9px 16px"}
        className="arrows"
        lineHeight="24px"
        fontWeight={600}
        fontSize="14px"
        borderRadius={props.radius ?? "12px"}
        bgColor={props.color ?? "#2E313A"}
        color="rgba(20, 20, 27, 1)"
        w={props.squareSize}
        h={props.squareSize}
        _hover={{
            bgColor: props.color ? '#FFC758' : '#323540'
        }}
        _active={{
            bgColor: props.color ? '#EEAD2E' : "#2C2E37"
        }}
    >
        {props.children}
    </Button>
}