export function GlobalIcon(props: {
    stroke?: string
}) {

    const strokeColor = props.stroke ?? "#1EF875"

    return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 18.3334C14.6024 18.3334 18.3334 14.6025 18.3334 10.0001C18.3334 5.39771 14.6024 1.66675 10 1.66675C5.39765 1.66675 1.66669 5.39771 1.66669 10.0001C1.66669 14.6025 5.39765 18.3334 10 18.3334Z" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.66661 2.5H7.49994C5.87494 7.36667 5.87494 12.6333 7.49994 17.5H6.66661" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.5 2.5C14.125 7.36667 14.125 12.6333 12.5 17.5" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M2.5 13.3333V12.5C7.36667 14.125 12.6333 14.125 17.5 12.5V13.3333" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M2.5 7.50024C7.36667 5.87524 12.6333 5.87524 17.5 7.50024" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
    </svg>

}