import { Box, Flex, useMediaQuery, Text, VStack } from "@chakra-ui/react";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { MainContent } from "./components/MainContent";
import { BgImageContainer } from "./components/BgImageContainer";

function App() {

  const [isLargerThan800] = useMediaQuery('(min-width: 369px)')

  if (!isLargerThan800) {
    return <Flex
      bgColor="rgba(11, 11, 13, 1)"
      color="white"
      width="100%"
      flexDir={"column"}
    >
      <BgImageContainer image="/img/bg1.jpg" h="100vh">
        <VStack
          alignItems="center"
          justifyContent={"center"}
        >
          <Text textAlign="center" fontFamily="Kanit" fontSize="44px" textTransform="uppercase" lineHeight="52px">The mobile version will be available soon</Text>
          <Text fontFamily="Open Sans" fontSize="16px" lineHeight={"22px"}>Please use the desktop version</Text>
        </VStack>
      </BgImageContainer>
    </Flex>
  }

  return (
    <Box bgColor="#11111F" fontFamily="Open Sans">
      <Header />
      <MainContent />
      <Footer />
    </Box>
  );
}

export default App;