import { Flex, VStack, Text, Box, Link, Img } from "@chakra-ui/react";
import { GlobalIcon } from "./icons/GlobalIcon";
import { GreenColor, SubtextColor, TextColor, YellowColor } from "../theme";
import { PageLayoutContainer } from "./PageLayoutContainer";
import { BgImageContainer } from "./BgImageContainer";
import { Slider } from "./Slider";
import { StartIcon } from "./icons/StartIcon";
import { GamblyButton } from "./GamblyButton";
import { ComponentsIcon } from "./icons/ComponentsIcon";
import { HeartIcon } from "./icons/HeartIcon";
import { PerksLine } from "./PerksLine";
import { HoveredPerks } from "./HoveredPerks";
import { TextWithInteractiveContent } from "./TextWithInteractiveContent";
import { useEffect, useRef } from "react";
import { useWindowSize } from "../hooks/useWindowSize";
import { PlatformItems } from "./PlatformItems";
import { getArrowSize, getArrowsPosition, getImagesCoordinates, getLayoutContainerWidth } from "../utils";

interface PositionProps {
    top?: string,
    left?: string
    bottom?: string
    right?: string
};

function ArrowsBlock(props: {
    children: any,
    top?: number
    left?: number,
    bottom?: number,
    right?: number,
    sx?: any
}) {


    let posProps: PositionProps = {}

    if (props.bottom) {
        posProps.bottom = `${props.bottom}px`
    }
    if (props.left) {
        posProps.left = `${props.left}px`
    }

    if (props.right) {
        posProps.right = `${props.right}px`
    }

    if (props.top) {
        posProps.top = `${props.top}px`
    }

    return <Box
        sx={props.sx}
        zIndex={10}
        cursor="pointer"
        // fontSize="16px"
        // fontFamily="Open Sans"
        // fontWeight={400}
        // bg={"#F4F4F4"}
        // padding={"10px 14px"}
        // border={"1px solid #D1D3DB"}
        // color={"#0B0B0D"}
        position={"absolute"}
        {...posProps}
        className="fadeanim"
        lineHeight="20px" >{props.children}</Box>
}

export function MainContent() {

    const setRef = useRef(false)
    const { width } = useWindowSize()
    const community_header_2 = () => {
        switch (!!width) { 
            case width >= 320 && width < 480:
                return ["Marketing and", "community support", "for cryptocurrency", "based projects"]   
            case width >= 480 && width < 960:
                return ["Marketing and community", "support for cryptocurrency", "based projects"];
            default:
                return ["Marketing and community support", "for cryptocurrency based projects"];
        }
    }

    const platform_header_2 = () => {
        switch (!!width) {
            case width >= 320 && width < 960:
                return ["Casino, Predictions", "& Sportsbook"];
            default:
                return ["Casino, Predictions & Sportsbook"];
        }
    }

    useEffect(() => {

        if (!setRef.current) {

            setTimeout(() => {

                const elementsArray = document.querySelectorAll(".slideOnScroll");

                function fadeIn() {

                    for (var i = 0; i < elementsArray.length; i++) {
                        var elem = elementsArray[i]
                        var distInView = elem.getBoundingClientRect().top - window.innerHeight + 200;
                        if (distInView < 0) {
                            elem.classList.add("shown");
                        }
                        // else {
                        // elem.classList.remove("shown");
                        // }
                    }
                }

                window.addEventListener("scroll", fadeIn)
                fadeIn();
            }, 800)

            setRef.current = true
        }
    }, [])

    return <VStack gap="46px">
        <BgImageContainer image="/img/bg1.jpg">
            <PageLayoutContainer gap="14px" sx={getLayoutContainerWidth(width)?.launchCasino}>
                <Flex gap="8px" fontSize={width < 640 ? "20px" : "24px"} fontWeight={400} color={YellowColor} justifyContent="center" alignItems="center" >
                    <GlobalIcon stroke={YellowColor} />
                    <Text fontFamily="Caveat" lineHeight={"19.2px"}>No fee set up available for qualified projects</Text>
                </Flex>
                <Flex fontSize={width < 640 ? "44px" : "62px"} lineHeight={width < 640 ? "52.8px" : "74.4px"} textTransform="uppercase" color={TextColor} fontWeight={600} justifyContent="center" alignItems="center" >
                    <Text textAlign={"center"} fontFamily="Kanit" className="fadetext">Launch your casino in 3 days</Text>
                </Flex>
                <Flex fontSize={width < 640 ? "16px" : "18px"} lineHeight={width < 640 ? "21px" : "24.51px"} color={SubtextColor} fontWeight={300} justifyContent="center" alignItems="center" >
                    <Text textAlign={"center"}>Cryptocurrency native fully functional gambling platform and operational/marketing support</Text>
                </Flex>
                <Box className="slideOnScroll shown" marginTop="32px" position="relative" boxShadow="0px 20px 40px 0px #00000054,0px 10px 20px 0px #00000054,0px 40px 80px 0px #00000054">
                    <ArrowsBlock 
                        left={getArrowsPosition(width)?.centerLeft.left} 
                        bottom={getArrowsPosition(width)?.centerLeft.bottom}
                    >
                        <Flex gap="5px">
                            <Box width={getArrowSize(width)?.width} height={getArrowSize(width)?.height}>
                                <Img objectFit={"contain"} src="/img/svg/arrows/center-left.png" className='swing animated' />
                            </Box>
                        </Flex>
                    </ArrowsBlock>
                    <ArrowsBlock 
                        left={getArrowsPosition(width)?.topLeft.left} 
                        top={getArrowsPosition(width)?.topLeft.top}
                    >
                        <Flex gap="8px" justifyContent="center" alignItems="center" >
                            <Box width={getArrowSize(width)?.width} height={getArrowSize(width)?.height} transform={"rotate(-2deg)"}>
                                <Img objectFit={"contain"} src="/img/svg/arrows/top-left.png" className='swing animated' />
                            </Box>
                        </Flex>
                    </ArrowsBlock>
                    <ArrowsBlock 
                        right={getArrowsPosition(width)?.centerRight.right} 
                        top={getArrowsPosition(width)?.centerRight.top}
                    >
                        <VStack gap="5px" justifyContent="center" alignItems="center" >
                            <Box width={getArrowSize(width)?.width} height={getArrowSize(width)?.height}>
                                <Img src="/img/svg/arrows/center-right.png" className='swing animated' />
                            </Box>
                        </VStack>
                    </ArrowsBlock>
                    <ArrowsBlock 
                        left={getArrowsPosition(width)?.bottomLeft.left} 
                        bottom={getArrowsPosition(width)?.bottomLeft.bottom}
                    >
                        <VStack gap="5px" justifyContent="center" alignItems="center" >
                            <Box width={getArrowSize(width)?.width} height={getArrowSize(width)?.height}>
                                <Img objectFit={"contain"} src="/img/svg/arrows/bottom-left.png" className='swing animated' />
                            </Box>
                        </VStack>
                    </ArrowsBlock>
                    <Slider slides={[
                        {
                            "image": "/img/slides/slide_1-min_1.png",
                        },
                        {
                            "image": "/img/slides/slide_3-min.jpg",
                        },
                        {
                            "image": "/img/slides/slide_8-min.png",
                        },
                        {
                            "image": "/img/slides/slide_5-min.png",
                        },
                        {
                            "image": "/img/slides/slide_2-min.png",
                        },
                        {
                            "image": "/img/slides/slide_4-min.jpg",
                        },
                        {
                            "image": "/img/slides/slide_6-min.png",
                        },
                        {
                            "image": "/img/slides/slide_7-min.png",
                        },
                    ]} />
                </Box>
            </PageLayoutContainer>
        </BgImageContainer>

        <PerksLayout
            sx={{ position: "relative", maxWidth: getLayoutContainerWidth(width)?.platform.maxWidth }}
            icon={<ComponentsIcon />}
            header_1="Your platform will include"
            header_2={platform_header_2()}
            width={width}
        >
            {width > 1200 ? (
                <Flex position="relative" height="653px" borderRadius="8px" zIndex={2} marginTop="100px" className="slideOnScroll">
                    <ArrowsBlock left={10} top={-65}>
                        <VStack gap="10px">
                            <Box h={"42px"} w={"145px"}>
                                <Img src="/img/svg/arrows/hover.png" className='swing animated' />
                            </Box>
                        </VStack>
                    </ArrowsBlock>
                    <Flex overflow="hidden" height="fit-content" borderRadius="8px" boxShadow="0px 20px 40px 0px #00000054,0px 10px 20px 0px #00000054,0px 40px 80px 0px #00000054">
                        <HoveredPerks imageXOffset={0} header="Casino" perks={["8,000 + games", "Customizable rewards system", "Bonus program", "Personal dashboard and analytics", "Multi chain deposits", "Dedicated support and VIP staff"]} image="/img/perks_1.png"></HoveredPerks>
                        <PerksLine left={true} />
                        <HoveredPerks imageXOffset={50} header="Predictions" perks={[
                            "Daily events added",
                            "Bets on breaking news in over 9 categories",
                            "Custom betting markets based on your requests",
                            "Live tracking and analytics",
                            "Unique marketing opportunities"
                        ]} image="/img/perks_3.png"></HoveredPerks>

                        <PerksLine />
                        <HoveredPerks imageXOffset={98.5} header="Sportsbook" perks={[
                            "40+ categories",
                            "Live stats",
                            "1500+ sport events",
                            "Personal dashboard",
                            "Multi chain deposits",
                            "210 000 events per month",
                            "125 sports",
                            "Risk management",
                        ]} image="/img/perks_2.png"></HoveredPerks>
                    </Flex>
                </Flex>
            ) : (
                <PlatformItems width={width} />
            )}
        </PerksLayout>


        <PerksLayout
            sx={{ marginTop: "90px", position: "relative", maxWidth: getLayoutContainerWidth(width)?.platform.maxWidth }}
            icon={<HeartIcon />}
            header_1="Community participation as a service"
            header_2={community_header_2()}
            width={width}
        >
            <PageLayoutContainer gap="14px" sx={getLayoutContainerWidth(width)?.platform}>
                { width >= 960 && 
                    <>
                        <Img 
                            src="/img/svg/element_2.svg" 
                            zIndex={1} 
                            position="absolute" 
                            left={getImagesCoordinates(width)?.element_1.left} 
                            top={getImagesCoordinates(width)?.element_1.top} 
                        />
                        <Img 
                            src="/img/svg/element_3.svg" 
                            zIndex={1} 
                            position="absolute" 
                            left={getImagesCoordinates(width)?.element_2.left} 
                            top={getImagesCoordinates(width)?.element_2.top} 
                        />
                    </> 
                }
                <VStack className="slideOnScroll" gap="14px" zIndex={2}>
                    <TextWithInteractiveContent
                        sx={{ marginTop: "70px", zIndex: 2 }}
                        direction="right"
                        heading="Casino challenges" 
                        text="Your players will be rewarded with surprise casino challenges to increase their wins on the platform! Deposit bonuses, new sign up bonuses and big wins competitions will enhance their time playing even more and increase interaction with your project announcements."
                    ><Img height="100%" src="/img/community/community_participation_1.png"></Img></TextWithInteractiveContent>

                    <TextWithInteractiveContent
                        sx={{ marginTop: "70px", zIndex: 2 }}
                        heading="Poker tournaments"
                        text="When you partner with Gambly, we will host complimentary poker tournaments for your community catering to any holder type or role you choose. The prizes will be paid for by us and won't require your staff to add extra work. This adds a big increase in volume on your casino platform, promotes 'time spent on site' plus raises awareness of your project."
                    ><Img width="100%" src="/img/community/poker_tournament_whole.png"></Img></TextWithInteractiveContent>

                    <TextWithInteractiveContent
                        sx={{ marginTop: "120px", marginBottom: "20px", zIndex: 2 }}
                        direction="right"
                        heading="Prediction contests"
                        text={`With a fun new experience to keep your project active through friendly competition, our staff will conduct "prediction contests" with your community which takes actual events from the platform and lets your members take part in predicting outcomes of real-world events. Whoever makes the most correct picks wins prizes provided by Gambly. You will be amazed by the amount of interaction these contests generate as a fun and free way to promote your platform while also introducing a whole new form of in-project entertainment.`}
                    ><Img src="/img/community/custom_events.png"></Img></TextWithInteractiveContent>
                </VStack>
            </PageLayoutContainer>
        </PerksLayout>

            <PageLayoutContainer sx={{ maxWidth: getLayoutContainerWidth(width)?.platform.maxWidth }}>
                <VStack gap="14px" height="550px" justifyContent="center" alignItems="center" className="slideOnScroll">
                    <Flex gap="8px" fontSize={width < 640 ? "20px" :  "24px"} fontWeight={400} color={YellowColor} justifyContent="center" alignItems="center" >
                        <StartIcon />
                        <Text fontFamily="Caveat">Let&apos;s start</Text>
                    </Flex>
                    <Flex fontSize={width < 640 ? "44px" : "64px"} textTransform="uppercase" lineHeight={width < 640 ? "52.8px" :  "77px"} color={TextColor} fontWeight={600} justifyContent="center" alignItems="center" >
                        <Text textAlign={"center"} fontFamily="Kanit">Let&apos;s discuss your project</Text>
                    </Flex>
                    <Flex fontSize={width < 640 ? "16px" :  "18px"} lineHeight={width < 640 ? "21.79px" :  "24.51px"} color={SubtextColor} fontWeight={300} justifyContent="center" alignItems="center" >
                        <Text textAlign={"center"}>Contact us at <Link href="mailto:team@elysiumigaming.com" color={YellowColor} textDecoration={"underline"}>team@elysiumigaming.com</Link> to begin the discussion about your project as a partner with Elysium iGaming or click the button below!</Text>
                    </Flex>
                    <Box mt="30px">
                        <GamblyButton color={YellowColor} p="18px 42px" radius="14px" onClick={() => {
                            window.location.href = "mailto:team@elysiumigaming.com";
                        }} >
                            <Text fontSize="24px">Contact us</Text>
                        </GamblyButton>
                    </Box>
                </VStack>
            </PageLayoutContainer>
    </VStack >
}

function PerksLayout(props: {
    header_1: string
    header_2: string[]
    icon: any,
    children?: any,
    sx?: React.CSSProperties,
    width?: number
}) {
    return <PageLayoutContainer gap="14px" sx={props.sx} >
        <Flex gap="8px" className="fadetext" zIndex="2" fontSize="24px" fontWeight={400} color={YellowColor} justifyContent="center" alignItems="center" >
            {props.icon}
            <Text fontFamily="Caveat" >{props.header_1}</Text>
        </Flex>
        <VStack zIndex="2" fontSize={props.width && props.width < 640 ? "36px" : "48px"} gap="0px" className="fadetext" color={TextColor} fontWeight={500} justifyContent="center" alignItems="center" lineHeight="57px" >
            {props.header_2.map((el, idx) => {
                return <Box key={`perk_${props.header_1}_${idx}`} fontFamily="Kanit">{el}</Box>
            })}
        </VStack>
        {props.children}
    </PageLayoutContainer>
}