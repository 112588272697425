import { Box, Flex } from "@chakra-ui/react";

export function BgImageContainer(props: {
    children: any,
    opacity?: number,
    image: string,
    h? : string
    transform?: string
}) {
    return <Flex zIndex="10" position="relative" w="100%" h={props.h ?? "auto"} pt={"100px"}>
        <Box style={{
            background: `url(${props.image})`,
            position: "absolute",
            left: "0",
            top: "0",
            transform: props.transform ?? "",
            display: "block",
            width: "100%",
            height: "100%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "50% 0",
            zIndex: 1,
            opacity: props.opacity ?? .3,
            backgroundSize: "cover",
        }}></Box>
        <Flex
            position="relative"
            zIndex="2"
            w="100%"
            justifyContent="center"
            marginBottom="74px"
        >{props.children}</Flex>
    </Flex >
}