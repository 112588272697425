import { Box, Divider, Flex } from "@chakra-ui/react";
import { GamblyButton } from "./GamblyButton";
// import { DiscordIcon } from "./icons/DiscordIcon";
import { HeaderLogo } from "./icons/HeaderLogo";
import { PageLayoutContainer } from "./PageLayoutContainer";
import { YellowColor } from "../theme";
import { useWindowSize } from "../hooks/useWindowSize";

export function Header() {
    const { width } = useWindowSize();
    return (
        <Flex paddingX="20px" paddingY="15px" justifyContent={"center"}>
            <PageLayoutContainer>
                <Flex w={"100%"}>
                    {width < 480 ? <HeaderLogo /> : (
                        <Box w={"194px"} h={"60px"}>
                            <HeaderLogo />
                        </Box>
                    )}
                    <Divider />
                    <Flex gap="10px" alignItems={"center"}>
                        <GamblyButton color={YellowColor} onClick={() => {
                            window.location.href = "mailto:team@elysiumigaming.com";
                        }}>Contact us</GamblyButton>
                    </Flex>
                </Flex>
            </PageLayoutContainer>
        </Flex>
    )
}

// <GamblyButton color="rgba(36, 37, 44, 1)" p="8px" squareSize="42px"><DiscordIcon /></GamblyButton>