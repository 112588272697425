import { Flex, Grid, Text, Image } from '@chakra-ui/react'
import React from 'react'
import { getGridTemplateConfig, platformItems } from '../utils'
import { YellowColor } from '../theme'

export const PlatformItems = ({ width }: { width: number }) => {
    const getImageHeight = () => {
        switch (!!width) {
            case width < 480:
                return "203px";

            case width < 640:
                return "271px";
        
            default:
                return "176px"
        }
    }
  return (
    <Grid gap={"14px"} gridTemplateColumns={getGridTemplateConfig(width)?.gridTemplateColumns} m={"50px 0px 0"}>
        {platformItems.map(({ id, title, src, additionalInfo }) => (
            <Flex
                direction={"column"}
                gap={"12px"} 
                bgColor={"#202036"}
                borderRadius={"8px"}
                key={id}
                p={"12px 0 49px 0"}
            >
                <Text
                    fontSize={"26px"}
                    lineHeight={"31px"}
                    fontWeight={500}
                    fontFamily="Kanit"
                    textAlign={"center"}
                    color={YellowColor}
                >
                    {title}
                </Text>
                <Image src={src} w={"100%"} height={getImageHeight()} alt={title} />
                <Flex direction={"column"} gap={"12px"} p={"12px"}>
                    {additionalInfo.map((info) => (
                        <Text
                            key={info}
                            fontFamily="Open Sans"
                            fontWeight={600}
                            fontSize={"18px"}
                            lineHeight={"24px"}
                            color={"#F4F4F4"}
                        >
                            {`- ${info}`}
                        </Text>
                    ))}
                </Flex>
            </Flex>
        ))}
    </Grid>
  )
}
