export const platformItems = [
    {
        id: 1,
        title: 'Casino',
        src: '/img/perks_4.png',
        additionalInfo: ["8,000 + games", "Customizable rewards system", "Bonus program", "Personal dashboard and analytics", "Multi chain deposits", "Dedicated support and VIP staff"]
    },
    {
        id: 2,
        title: 'Predictions',
        src: '/img/perks_5.png',
        additionalInfo: [
            "Daily events added",
            "Bets on breaking news in over 9 categories",
            "Custom betting markets based on your requests",
            "Live tracking and analytics",
            "Unique marketing opportunities"
        ]
    },
    {
        id: 3,
        title: 'Sportsbook',
        src: '/img/perks_6.png',
        additionalInfo: [
            "40+ categories",
            "Live stats",
            "1500+ sport events",
            "Personal dashboard",
            "Multi chain deposits",
            "210 000 events per month",
            "125 sports",
            "Risk management",
        ]
    },
]

export const getImagesCoordinates = (width: number) => {
    switch (true) {
        case width >= 1200:
            return {
                element_1: {
                    left: '20',
                    top: '100px'
                },
                element_2: {
                    left: '180',
                    top: '650px'
                }
            }
    
        case width >= 960:
            return {
                element_1: {
                    left: '5',
                    top: '130px'
                },
                element_2: {
                    left: '100',
                    top: '650px'
                }
            }
    }
}

export const getLayoutContainerWidth = (width: number) => {
    switch (true) {
        case width < 480:
            return {
                launchCasino: {
                    maxWidth: '370px'
                },
                platform: {
                    maxWidth: '370px'
                }
            }
        case width < 640:
            return {
                launchCasino: {
                    maxWidth: '430px'
                },
                platform: {
                    maxWidth: '450px'
                }
            }
        case width < 960:
            return {
                launchCasino: {
                    maxWidth: '600px'
                },
                platform: {
                    maxWidth: '620px'
                }
            }
        case width >= 1200:
            return {
                launchCasino: {
                    maxWidth: '1160px'
                },
                platform: {
                    maxWidth: '1160px'
                }
            }
        case width < 1200:
            return {
                launchCasino: {
                    maxWidth: '900px'
                },
                platform: {
                    maxWidth: '940px'
                }
            }
        default:
            break;
    }
}

export const getArrowsPosition = (width: number) => {
    switch (true) {
        case width >= 1200:
            return {
                centerLeft: {
                    left: 110,
                    bottom: 280
                },
                topLeft: {
                    left: 150,
                    top: -30
                },
                centerRight: {
                    right: 40,
                    top: 200
                },
                bottomLeft: {
                    left: 120,
                    bottom: 120
                }
            }
        case width >= 960 && width < 1200:
            return {
                centerLeft: {
                    left: 110,
                    bottom: 220
                },
                topLeft: {
                    left: 130,
                    top: -20
                },
                centerRight: {
                    right: 10,
                    top: 160
                },
                bottomLeft: {
                    left: 80,
                    bottom: 90
                }
            }
        case width >= 640 && width < 960:
            return {
                centerLeft: {
                    left: 70,
                    bottom: 140
                },
                topLeft: {
                    left: 130,
                    top: -30
                },
                centerRight: {
                    right: 10,
                    top: 80
                },
                bottomLeft: {
                    left: 10,
                    bottom: 50
                }
            }
        case width >= 480 && width < 640:
            return {
                centerLeft: {
                    left: 50,
                    bottom: 100
                },
                topLeft: {
                    left: 50,
                    top: -20
                },
                centerRight: {
                    right: 10,
                    top: 60
                },
                bottomLeft: {
                    left: 30,
                    bottom: 40
                }
            }
        case width >= 320 && width < 480:
            return {
                centerLeft: {
                    left: 30,
                    bottom: 90
                },
                topLeft: {
                    left: 50,
                    top: -20
                },
                centerRight: {
                    right: 10,
                    top: 30
                },
                bottomLeft: {
                    left: 0,
                    bottom: 40
                }
            }
    }
}

export const getArrowSize = (width: number) => {
    switch (true) {
        case width >= 1200:
            return {
                width: '171px',
                height: '42px'
            }
        case width >= 640 && width < 1200:
            return {
                width: '153px',
                height: '35px'
            }
        case width >= 320 && width < 640:
            return {
                width: '113px',
                height: '22px'
            }
    }
}

export const getGridTemplateConfig = (width: number) => {
    switch (!!width) {
        case width >= 640 && width < 960:
            return {
                gridTemplateColumns: "repeat(2, 1fr)"
            }
        case width >= 960:
            return {
                gridTemplateColumns: "repeat(3, 1fr)"
            }
    }
}
