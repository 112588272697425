import { Box, Divider, Flex, VStack } from "@chakra-ui/react";
import { GreenColor, TextColor, YellowColor } from "../theme";
import React from "react";
import { useWindowSize } from "../hooks/useWindowSize";

export function TextWithInteractiveContent(props: {
    direction?: "left" | "right",
    children: any,
    heading: string,
    text: string,
    sx?: React.CSSProperties
}) {
    const { width } = useWindowSize();

    const { direction = "left" } = props;

    return <Flex
        style={props.sx}
        direction={width < 960 ? "column-reverse" : (direction == "left" ? "row-reverse" : "row")}
        gap="40px"
        height={width < 960 ? "100%" : "352px"}
        justifyContent="center" alignItems="center"
    >
        <Flex w="100%" justifyContent={width < 960 ? "start" : "initial"}>
            <TextBlock heading={props.heading} text={props.text} width={width} />
        </Flex>
        {/* <Divider/> */}
        <Flex w="100%" height="100%">
            {props.children}
        </Flex>
    </Flex>
}

function TextBlock(props: {
    heading: string,
    text: string,
    width: number
}) {

    return <VStack gap="20px" maxW={props.width < 960 ? "100%" :  "80%"}>
        <Box
            w="100%"
            whiteSpace="nowrap"
            fontSize={props.width < 640 ? "36px" : "52px"}
            lineHeight={props.width < 640 ? "43.2px" : "62.5px"}
            fontWeight={500}
            fontFamily="Kanit"
            textAlign="left"
            color={YellowColor}
        >{props.heading}</Box>
        <Box
            fontSize={props.width < 640 ? "16px" :  "18px"}
            lineHeight={props.width < 640 ? "21.79px" :  "24.5px"}
            fontWeight={400}
            color={TextColor}
        >{props.text}</Box>
    </VStack>
}