import { Flex, VStack, propNames } from "@chakra-ui/react";
import { Heart2Icon } from "./icons/Heart2Icon";
import { StarIcon } from "./icons/StarIcon";
import { SmilingFaceIcon } from "./icons/SmilingFace";
import { PerksText } from "./PerksText";
import { YellowColor } from "../theme";

export function PerksLine(props: {
    left?: boolean
}) {
    return <VStack
        bgColor={YellowColor}
        color="#0B0B0E"
        flexShrink={0}
        flexGrow={0}
        width="38px"
        zIndex={2}
        justifyContent="center"
        alignItems="center"
        overflow="hidden"
        height="632px"
        boxShadow="0px 40px 80px 0px rgba(0, 0, 0, 1),0px 20px 40px 0px rgba(0, 0, 0, 1),0px 10px 20px 0px rgba(0, 0, 0, 1)">
        {props.left ? <>
            <PerksText>Fast development - from 7 days</PerksText>
            <VerticalElement><StarIcon /></VerticalElement>
            <PerksText>After launch support</PerksText>
            <VerticalElement><Heart2Icon /></VerticalElement>
            <PerksText>Personal dashboard</PerksText>
            <VerticalElement><SmilingFaceIcon /></VerticalElement>
            <PerksText>Fast development - from 7 days</PerksText>
        </> : <>
            <PerksText>Full-cycle production</PerksText>
            <VerticalElement><Heart2Icon /></VerticalElement>
            <PerksText>Assistance with the community</PerksText>
            <VerticalElement><SmilingFaceIcon /></VerticalElement>
            <PerksText>Legal support</PerksText>
            <VerticalElement><StarIcon /></VerticalElement>
            <PerksText>Full-cycle production</PerksText>
        </>}
    </VStack>
}

function VerticalElement(props: { children: any }) {
    return <Flex
        flexShrink={0}
        gap="20px"
        style={{ writingMode: "vertical-lr" }}
    >{props.children}</Flex>
}