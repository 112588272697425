import { VStack } from "@chakra-ui/react";

export function PageLayoutContainer(props: {
    children: any,
    gap?: string,
    sx?: React.CSSProperties
}) {

    return <VStack gap={props.gap} position="relative" w="100%" style={props.sx}>
        {props.children}
    </VStack>
}