import { Box, Flex, Img, VStack } from "@chakra-ui/react";
import { GreenColor, SubtextColor, YellowColor } from "../theme";
import { useState } from "react";

export function HoveredPerks(props: {
    image: string,
    imageXOffset?: number,
    header: string,
    perks: string[]
}) {

    const [hover, setHover] = useState(false);

    return <Flex position="relative"
        height="632px"
        width="362px"
        onMouseEnter={() => {
            setHover(true)
        }} onMouseLeave={() => {
            setHover(false)
        }}>
        <Box
            style={{
                background: `url(${props.image})`,
                position: "absolute",
                top: "0",
                display: "block",
                height: "632px",
                width: "362px",
                backgroundRepeat: "no-repeat",
                backgroundPositionX: `${props.imageXOffset ?? 0}%`,
                zIndex: 1,
                backgroundSize: "cover"
            }}
        ></Box>
        <VStack
            opacity={hover ? 1 : 0}
            cursor="pointer"
            transitionDuration=".2s"
            transitionProperty="opacity"
            w="100%"
            h="100%"
            bgColor="rgba(0,0,0,.75)"
            position="absolute"
            top="0"
            left="0"
            zIndex="5"
            justifyContent="center"
            alignItems="center"
        >
            <Flex position="absolute"
                top="0"
                left="0"
                marginTop="32px"
                fontSize="32px"
                w="100%"
                justifyContent="center" alignItems="center" color={SubtextColor} fontFamily="Kanit">
                {props.header}
            </Flex>
            <VStack
                gap="30px"
                width="80%"
                flexGrow={0}
                fontSize="32px"
                color={YellowColor}
                fontFamily="Caveat"
                justifyContent="center" alignItems="center">
                {props.perks.map(el => {
                    return <Box key={`perk_${el}`} lineHeight="25px" w="100%" textAlign="center">{el}</Box>
                })}
            </VStack>
        </VStack>
    </Flex>
}