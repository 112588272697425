import { Box, Img } from "@chakra-ui/react";
import { GamblyButton } from "./GamblyButton";
import { useState } from "react";
import { YellowColor } from "../theme";
import { useWindowSize } from "../hooks/useWindowSize";

interface SlideData {
    image: string
}

export function Slider(props: {
    slides: SlideData[]
}) {
    const { width } = useWindowSize();

    const [curSlide, setSlide] = useState(0);

    let curSlideInfo = props.slides[curSlide]

    return <Box position="relative">
        {width >= 640 &&
            <>
                <Box position="absolute" left="-17px" top="50%" translateY="-50%">
                    <GamblyButton
                        // color="rgba(46, 49, 58, 1)"
                        p="auto"
                        radius="8px"
                        onClick={() => {
                            const nextSlide = curSlide - 1;
                            if (nextSlide >= 0) {
                                setSlide(nextSlide);
                            }
                        }}
                        squareSize="34px">
                        <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.67447 1.04612L2.61432 4.38824L0.736292 6.4289C-0.0549602 7.29306 -0.0549602 8.69863 0.736292 9.56279L5.67447 14.956C6.32272 15.664 7.42857 15.1538 7.42857 14.1647V8.32381V1.8374C7.42857 0.837893 6.32272 0.338138 5.67447 1.04612Z" fill={YellowColor} />
                        </svg>
                    </GamblyButton>
                </Box>
                <Box position="absolute" right="-17px" top="50%" translateY="-50%">
                    <GamblyButton
                        // color="rgba(46, 49, 58, 1)"
                        p="auto"
                        radius="8px"
                        onClick={() => {
                            const nextSlide = curSlide + 1;
                            if (nextSlide >= 0 && nextSlide < props.slides.length) {
                                setSlide(nextSlide);
                            }
                        }}
                        squareSize="34px">
                        <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.32556 14.9539L5.38571 11.6118L7.26374 9.5711C8.05499 8.70694 8.05499 7.30138 7.26374 6.43722L2.32556 1.04402C1.67731 0.336039 0.571463 0.846205 0.571463 1.8353L0.571463 7.67619L0.571462 14.1626C0.571462 15.1621 1.67731 15.6619 2.32556 14.9539Z" fill={YellowColor} />
                        </svg>
                    </GamblyButton>
                </Box>
            </>  
        }
        <Box borderRadius="8px" overflow="hidden">
            <Img width="100%" src={curSlideInfo.image} />
        </Box>
    </Box>
}